var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import RCPicker from 'rc-picker';
import enUS from '../locale/en_US';
import { getPlaceholder, transPlacement2DropdownAlign } from '../util';
import devWarning from '../../_util/devWarning';
import { ConfigContext } from '../../config-provider';
import LocaleReceiver from '../../locale-provider/LocaleReceiver';
import SizeContext from '../../config-provider/SizeContext';
import { getTimeProps, Components, } from '.';
import { FormItemStatusContext } from '../../form/context';
import { getFeedbackIcon, getMergedStatus, getStatusClassNames, } from '../../_util/statusUtils';
export default function generatePicker(generateConfig) {
    function getPicker(picker, displayName) {
        class Picker extends React.Component {
            constructor(props) {
                super(props);
                this.pickerRef = React.createRef();
                this.focus = () => {
                    if (this.pickerRef.current) {
                        this.pickerRef.current.focus();
                    }
                };
                this.blur = () => {
                    if (this.pickerRef.current) {
                        this.pickerRef.current.blur();
                    }
                };
                this.renderFeedback = (prefixCls) => (<FormItemStatusContext.Consumer>
          {({ hasFeedback, status: contextStatus }) => {
                        const { status: customStatus } = this.props;
                        const status = getMergedStatus(contextStatus, customStatus);
                        return hasFeedback && getFeedbackIcon(prefixCls, status);
                    }}
        </FormItemStatusContext.Consumer>);
                this.renderSuffix = (prefixCls, mergedPicker) => (<>
          {mergedPicker === 'time' ? <ClockCircleOutlined /> : <CalendarOutlined />}
          {this.renderFeedback(prefixCls)}
        </>);
                this.renderPicker = (contextLocale) => {
                    const locale = Object.assign(Object.assign({}, contextLocale), this.props.locale);
                    const { getPrefixCls, direction, getPopupContainer } = this.context;
                    const _a = this.props, { prefixCls: customizePrefixCls, getPopupContainer: customizeGetPopupContainer, className, size: customizeSize, bordered = true, placement, placeholder, status: customStatus } = _a, restProps = __rest(_a, ["prefixCls", "getPopupContainer", "className", "size", "bordered", "placement", "placeholder", "status"]);
                    const { format, showTime } = this.props;
                    const prefixCls = getPrefixCls('picker', customizePrefixCls);
                    const additionalProps = {
                        showToday: true,
                    };
                    let additionalOverrideProps = {};
                    if (picker) {
                        additionalOverrideProps.picker = picker;
                    }
                    const mergedPicker = picker || this.props.picker;
                    additionalOverrideProps = Object.assign(Object.assign(Object.assign({}, additionalOverrideProps), (showTime ? getTimeProps(Object.assign({ format, picker: mergedPicker }, showTime)) : {})), (mergedPicker === 'time'
                        ? getTimeProps(Object.assign(Object.assign({ format }, this.props), { picker: mergedPicker }))
                        : {}));
                    const rootPrefixCls = getPrefixCls();
                    return (<SizeContext.Consumer>
            {size => {
                            const mergedSize = customizeSize || size;
                            return (<FormItemStatusContext.Consumer>
                  {({ hasFeedback, status: contextStatus }) => (<RCPicker ref={this.pickerRef} placeholder={getPlaceholder(mergedPicker, locale, placeholder)} suffixIcon={this.renderSuffix(prefixCls, mergedPicker)} dropdownAlign={transPlacement2DropdownAlign(direction, placement)} clearIcon={<CloseCircleFilled />} prevIcon={<span className={`${prefixCls}-prev-icon`}/>} nextIcon={<span className={`${prefixCls}-next-icon`}/>} superPrevIcon={<span className={`${prefixCls}-super-prev-icon`}/>} superNextIcon={<span className={`${prefixCls}-super-next-icon`}/>} allowClear transitionName={`${rootPrefixCls}-slide-up`} {...additionalProps} {...restProps} {...additionalOverrideProps} locale={locale.lang} className={classNames({
                                        [`${prefixCls}-${mergedSize}`]: mergedSize,
                                        [`${prefixCls}-borderless`]: !bordered,
                                    }, getStatusClassNames(prefixCls, getMergedStatus(contextStatus, customStatus), hasFeedback), className)} prefixCls={prefixCls} getPopupContainer={customizeGetPopupContainer || getPopupContainer} generateConfig={generateConfig} components={Components} direction={direction}/>)}
                </FormItemStatusContext.Consumer>);
                        }}
          </SizeContext.Consumer>);
                };
                if (process.env.NODE_ENV !== 'production')
                    devWarning(picker !== 'quarter', displayName, `DatePicker.${displayName} is legacy usage. Please use DatePicker[picker='${picker}'] directly.`);
            }
            render() {
                return (<LocaleReceiver componentName="DatePicker" defaultLocale={enUS}>
            {this.renderPicker}
          </LocaleReceiver>);
            }
        }
        Picker.contextType = ConfigContext;
        if (displayName) {
            Picker.displayName = displayName;
        }
        return Picker;
    }
    const DatePicker = getPicker();
    const WeekPicker = getPicker('week', 'WeekPicker');
    const MonthPicker = getPicker('month', 'MonthPicker');
    const YearPicker = getPicker('year', 'YearPicker');
    const TimePicker = getPicker('time', 'TimePicker');
    const QuarterPicker = getPicker('quarter', 'QuarterPicker');
    return { DatePicker, WeekPicker, MonthPicker, YearPicker, TimePicker, QuarterPicker };
}
