import React from 'react';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import classNames from 'classnames';
import { tuple } from './type';
const InputStatuses = tuple('warning', 'error', '');
const iconMap = {
    success: CheckCircleFilled,
    warning: ExclamationCircleFilled,
    error: CloseCircleFilled,
    validating: LoadingOutlined,
};
export const getFeedbackIcon = (prefixCls, status) => {
    const IconNode = status && iconMap[status];
    return IconNode ? (<span className={`${prefixCls}-feedback-icon`}>
      <IconNode />
    </span>) : null;
};
export function getStatusClassNames(prefixCls, status, hasFeedback) {
    return classNames({
        [`${prefixCls}-status-success`]: status === 'success',
        [`${prefixCls}-status-warning`]: status === 'warning',
        [`${prefixCls}-status-error`]: status === 'error',
        [`${prefixCls}-status-validating`]: status === 'validating',
        [`${prefixCls}-has-feedback`]: hasFeedback,
    });
}
export const getMergedStatus = (contextStatus, customStatus) => customStatus || contextStatus;
